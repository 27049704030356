import React, { Fragment } from 'react';
//import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { saasClassicTheme } from '../common/theme/index';
import ResetCSS from '../common/style';
import { GlobalStyle, ContentWrapper } from '../components/aiexpertsedge.style';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import BannerSection from '../components/Banner/index';
import Navbar from '../components/Navbar';
import FeatureSection from '../components/Feature';
import TestimonialSection from '../components/Testimonial';
import PricingSection from '../components/Pricing';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Disclaimer from '../components/Disclaimer';
import { Helmet } from 'react-helmet';
import UserTestimonialSection from '../components/UserTestimonials';
import UniversitySection from '../components/Universities/index';

const AiExpertsEdgeLandingPage = () => {

  return (
    <ThemeProvider theme={ saasClassicTheme }>
      <Fragment>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <BannerSection />
          <UniversitySection />
          <FeatureSection />
          <PricingSection />
          <TestimonialSection />
          <UserTestimonialSection />
          <ContactUs />
          <Disclaimer />
          <Footer />
        </ContentWrapper>
      </Fragment>
      
    </ThemeProvider>
  );
};

export default AiExpertsEdgeLandingPage;
